/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
// import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  // ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

// const CustomLinkComponent = ({ linkConfig, currentPage }) => {
//   const { group, text, type, href, route } = linkConfig;
//   const getCurrentPageClass = page => {
//     const hasPageName = name => currentPage?.indexOf(name) === 0;
//     const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
//     const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
//     const isCurrentPage = currentPage === page;

//     return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
//       ? css.currentPage
//       : null;
//   };

//   // Note: if the config contains 'route' keyword,
//   // then in-app linking config has been resolved already.
//   if (type === 'internal' && route) {
//     // Internal link
//     const { name, params, to } = route || {};
//     const className = classNames(css.navigationLink, getCurrentPageClass(name));
//     return (
//       <NamedLink name={name} params={params} to={to} className={className}>
//         <span className={css.menuItemBorder} />
//         {text}
//       </NamedLink>
//     );
//   }
//   return (
//     <ExternalLink href={href} className={css.navigationLink}>
//       <span className={css.menuItemBorder} />
//       {text}
//     </ExternalLink>
//   );
// };

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    // customLinks,
    onLogout,
    isBuyer,
  } = props;

  // const user = ensureCurrentUser(currentUser);

  // const extraLinks = customLinks.map(linkConfig => {
  //   return (
  //     <CustomLinkComponent
  //       key={linkConfig.text}
  //       linkConfig={linkConfig}
  //       currentPage={currentPage}
  //     />
  //   );
  // });

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    // const signupOrLogin = (
    //   <span className={css.authenticationLinks}>
    //     <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
    //   </span>
    // );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            {/* <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            /> */}
            {signup}
            {login}
          </div>

          <div className={css.customLinksWrapper}>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('TermsOfServicePage'))}
              name="TermsOfServicePage"
            >
              <FormattedMessage id="TopbarMobileMenu.termsOfServicePageLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('AboutUsPage'))}
              name="AboutUsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.aboutUsPageLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ContactUsPage'))}
              name="ContactUsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.contactUsPageLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('FAQPage'))}
              name="FAQPage"
            >
              <FormattedMessage id="TopbarMobileMenu.fAQPageLink" />
            </NamedLink>
          </div>

          {/* <div className={css.spacer} /> */}
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  // const displayName = user.attributes.profile.firstName;
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        {/* <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span> */}
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.accountLinksWrapper}>
          <NamedLink
            className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          {!isBuyer ? (
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
            </NamedLink>
          ) : null}
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
        </div>
        {/* <div className={css.customLinksWrapper}>{extraLinks}</div> */}
        {/* <div className={css.spacer} /> */}
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
